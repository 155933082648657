import React, { useEffect } from "react"
import { useFtcLive } from "../contexts/FtcLiveContext";
import { usePersistentState } from "../helpers/persistant";
import {isAward, isMatch, UpdateType, UpdateTypes} from "../types/FtcLive";
import {useObsStudio} from "../contexts/ObsStudioContext";

interface MatchRow {
  number: number;
  name: string;
  scheduledTime?: number;
  award_mode: boolean
  blue1?: number;
  blue2?: number;
  blue3?: number;
  red1?: number;
  red2?: number;
  red3?: number;
  MATCH_LOAD?: number;
  SHOW_PREVIEW?: number;
  SHOW_RANDOM?: number;
  SHOW_MATCH?: number;
  MATCH_START?: number;
  MATCH_ABORT?: number;
  MATCH_COMMIT?: number;
  MATCH_POST?: number;
}

export type TeamData = {
  number: number;
  name: string;
  school: string;
  cit: string;
  state: string;
  country: string;
  rookie: number;
}

type Team = {
  number: number;
  name: string;
}

function categoryRemapper(category: UpdateType): UpdateType{
  const remap = {
    "SHOW_RESULTS": "MATCH_POST",
        "START_MATCH": "MATCH_START",
    "ABORT_MATCH": "MATCH_ABORT",
    "SHOW_SETUP":"MATCH_LOAD",
  }
  // @ts-ignore
  return remap[category] ?? category

}


const MatchEventsTable: React.FC = () => {
  const [rows, setRows] = usePersistentState<MatchRow[]>('Match_Events', [])
  const [teams, setTeams] = usePersistentState<Team[]>('Teams', [])
  const [chapters, setChapters] = usePersistentState<string[]>('Video_Chapters', [])
  const [offsetTime, setOffsetTime] = usePersistentState<number>('Offset_Time', 0)
  const { isConnected, selectedEvent } = useFtcLive()
  const { latestStreamData } = useFtcLive()
  const { startStreamTime } = useObsStudio()
  const [useStreamTime, setUseStreamTime] = usePersistentState<boolean>('Use_Stream_Time', false)


  useEffect(() => {
    function addTeam(team:TeamData){
      if(!team?.number) return
      if (teams.find(t => t.number === team.number)) return
      setTeams(currentTeams=>{return[...currentTeams,{number:team.number,name:team.name}]})
    }
    if (latestStreamData) {
      if (["TIMEOUT_UPDATE", "PIT_UPDATE", "SCORE_UPDATE"].includes(latestStreamData.type)  ) {
        return
      }

      console.debug("ZZZZ",latestStreamData)
      if (!UpdateTypes.includes(categoryRemapper(latestStreamData.type))) {
        console.log("Unknown type", categoryRemapper(latestStreamData.type))
        return
      }
      if  (isMatch(latestStreamData.params)) {
        console.log("Setting matchName:", latestStreamData.params?.matchName, " index:", latestStreamData.index, " category:", latestStreamData.type, " @ Time:", latestStreamData.ts)
      }
      else if (isAward(latestStreamData.params)) {
        console.log("Setting awards:", latestStreamData.params.award.name, " index:", latestStreamData.index, " category:", latestStreamData.type, " @ Time:", latestStreamData.ts)
      }

      setRows(currentRows => {
        // Check if the row already exists
        if  (isMatch(latestStreamData.params)) {

          var matchName = latestStreamData.params.matchName
          if (!latestStreamData.params.elims) {
            matchName = "Q"+ latestStreamData.params.number
          }
          let rowIndex = currentRows.findIndex(row => row.name === matchName);
          if (!(latestStreamData.ts >0)) {
            console.error("Skipping bogus timestamp", latestStreamData.type, latestStreamData.ts)
            return currentRows
          }

          if (rowIndex !== -1) {
            console.debug('update row');
            // Clone the array and update the specific row
            const newRows = [...currentRows];
            let newRow = {...newRows[rowIndex]};
            // @ts-ignore
            newRow[categoryRemapper(latestStreamData.type)] = latestStreamData.ts;
            newRow.name = matchName;
            newRows[rowIndex] = newRow;
            return newRows;
          } else {
            if (!latestStreamData.params.red) return currentRows
            console.debug('create new row');

            // Create a new row and add it to the array
            console.debug(latestStreamData.params)

            console.debug(latestStreamData.type)
            let matchName = latestStreamData.params.matchName
            if (!latestStreamData.params.elims) {
              matchName = "Q"+ latestStreamData.params.number
            }
            let newRow: MatchRow = {
              award_mode: false,
              number: latestStreamData.index,
              name: matchName,


              [categoryRemapper(latestStreamData.type)]: latestStreamData.ts,
              red1: latestStreamData.params.red.teams[0].number,
              red2: latestStreamData.params.red.teams[1].number,
              red3: latestStreamData.params.red.teams[2]?.number,
              blue1: latestStreamData.params.blue.teams[0].number,
              blue2: latestStreamData.params.blue.teams[1].number,
              blue3: latestStreamData.params.blue.teams[2]?.number,
            };
            addTeam(latestStreamData.params.red.teams[0] as TeamData)
            addTeam(latestStreamData.params.red.teams[1] as TeamData)
            addTeam(latestStreamData.params.red.teams[2] as TeamData)
            addTeam(latestStreamData.params.blue.teams[0] as TeamData)
            addTeam(latestStreamData.params.blue.teams[1] as TeamData)
            addTeam(latestStreamData.params.blue.teams[2] as TeamData)
            return [...currentRows, newRow];
          }
        } else if (latestStreamData.type === "SHOW_AWARD" && isAward(latestStreamData.params)) {
          if (latestStreamData.params.place !== -1) return currentRows
          const awardName = latestStreamData.params.award.name
          let rowIndex = currentRows.findIndex(row => row.name === awardName);
          if (rowIndex !== -1){
            console.debug('update row');
            // Clone the array and update the specific row
            const newRows = [...currentRows];
            let newRow = {...newRows[rowIndex]};
            newRow.SHOW_PREVIEW = latestStreamData.ts;
            newRows[rowIndex] = newRow;
            return newRows;
          } else {
            let newRow: MatchRow = {
              award_mode: true,
              name: latestStreamData.params.award.name,
              SHOW_PREVIEW: latestStreamData.ts,
              number: latestStreamData.index,
            }
            return [...currentRows, newRow];
          }
        }
        else {
          // Unhandled stuff
          console.error("**Unhandled message", latestStreamData.type)
          return currentRows
        }
      });
    }
  }, [latestStreamData, setRows, setTeams, teams]); // Removed 'rows' and 'setRows' from the dependencies



  useEffect(() => {

    function calcTimeString(actionTime:number, firstTime:number) {
      let time = ((actionTime ?? 0) - firstTime) / 1000 + offsetTime
      let timeString = "N/A"
      if (time >= 0) {
        // Negative times look bad so show N/A instead
        const hours = Math.floor(time / 3600)
        time -= hours * 3600
        const minutes = Math.floor(time / 60)
        time -= minutes * 60
        const seconds = Math.floor(time)
        timeString = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
      }
      return timeString;
    }

    const getTeamName = (number?: number) => {
      if (!number) return undefined;
      return teams.find(team => team.number === number)
    }


    rows.sort((prev, curr) => {
      // Ensure that values without preview times get sorted to the end
      // Otherwise when loading a match list, you get weird negative values on run matches
      const prev_time = prev.SHOW_PREVIEW ?? 0
      const curr_time = curr.SHOW_PREVIEW ?? 0
      // Use match number as a tie-breaker
      if(prev_time === curr_time) return prev.number-curr.number
      if(prev_time === 0) return 1
      if(curr_time === 0) return -1
      return prev_time - curr_time
    });

    const firstTime = useStreamTime? startStreamTime:rows[0]?.SHOW_PREVIEW ?? 0
    let chapters: string[] = rows.map(r => {
      if (r.award_mode) {
        let timeString = calcTimeString(r.SHOW_PREVIEW ?? r.SHOW_MATCH ?? r.MATCH_START ?? 0, firstTime);
        return `${timeString} 🏆 ${r.name}`

      } else {
        let blueTeams = `🔵${r.blue1} ${getTeamName(r.blue1)?.name} 🔵 ${r.blue2} ${getTeamName(r.blue2)?.name}`
        if (r.blue3)
          blueTeams += `${r.blue3} ${getTeamName(r.blue3)?.name}`
        let redTeams = `🔴 ${r.red1} ${getTeamName(r.red1)?.name} 🔴 ${r.red2} ${getTeamName(r.red2)?.name}`
        if (r.red3)
          redTeams += `${r.red3} ${getTeamName(r.red3)?.name}`
        let timeString = calcTimeString(r.SHOW_PREVIEW ?? r.SHOW_MATCH ?? r.MATCH_START ?? 0, firstTime);
        return `${timeString} ${r.name} ${blueTeams} ${redTeams}`
      }
    })
    setChapters(['00:00:00 Event Start', ...chapters])
  }, [rows, setChapters, teams, offsetTime, startStreamTime, useStreamTime])


  const fetchMatches = async () => {
  }

  const clearRows = () => {
    setRows([])
    setTeams([])
    setOffsetTime(0)
    setUseStreamTime(false)
  }

  const exportData = () => {
    const fileData = JSON.stringify({ matches: rows, teams: teams }, null, 2);
    const blob = new Blob([fileData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = `${selectedEvent?.eventCode ?? 'event_data'}.json`
    link.href = url;
    link.click();

    URL.revokeObjectURL(url);
  }

  return (
    <div className="section">
      <button onClick={fetchMatches} disabled={!isConnected}>Get Match List</button>
      <button onClick={clearRows}>Clear All Data</button>
      <button onClick={exportData}>Export Data</button>
      <table border={1}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            {/*<th>Schedule Time</th>*/}
            <th>Blue 1</th>
            <th>Blue 2</th>
            {/*<th>Blue 3</th>*/}
            <th>Red 1</th>
            <th>Red 2</th>
            {/*<th>Red 3</th>*/}
            <th>LOAD</th>
            <th>SHOW PREVIEW</th>
            {/*<th>SHOW RANDOM</th>*/}
            <th>SHOW MATCH</th>
            <th>START</th>
            <th>ABORT</th>
            {/*<th>COMMIT</th>*/}
            <th>POST</th>
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={row.name}>
              <td>{row.number}</td>
              <td>{row.name}</td>
              {/*<td>{row.scheduledTime ? new Date(row.scheduledTime).toLocaleTimeString() : ''}</td>*/}
              <td>{row.blue1}</td>
              <td>{row.blue2}</td>
              {/*<td>{row.blue3}</td>*/}
              <td>{row.red1}</td>
              <td>{row.red2}</td>
              {/*<td>{row.red3}</td>*/}
              <td>{row.MATCH_LOAD ? new Date(row.MATCH_LOAD).toLocaleTimeString() : ''}</td>
              <td>{row.SHOW_PREVIEW ? new Date(row.SHOW_PREVIEW).toLocaleTimeString() : ''}</td>
              {/*<td>{row.SHOW_RANDOM ? new Date(row.SHOW_RANDOM).toLocaleTimeString() : ''}</td>*/}
              <td>{row.SHOW_MATCH ? new Date(row.SHOW_MATCH).toLocaleTimeString() : ''}</td>
              <td>{row.MATCH_START ? new Date(row.MATCH_START).toLocaleTimeString() : ''}</td>
              <td>{row.MATCH_ABORT ? new Date(row.MATCH_ABORT).toLocaleTimeString() : ''}</td>
              {/*<td>{row.MATCH_COMMIT ? new Date(row.MATCH_COMMIT).toLocaleTimeString() : ''}</td>*/}
              <td>{row.MATCH_POST ? new Date(row.MATCH_POST).toLocaleTimeString() : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      Video Offset Time (seconds to Match 1 Show Preview)
      <input
        type="number"
        placeholder='Offset Time'
        value={offsetTime}
        onChange={(e) => setOffsetTime(parseInt(e.target.value))}
      />
      <br />
      Use streaming start time {new Date(startStreamTime).toLocaleTimeString()} for reference time:
      <input type="checkbox"
             checked={useStreamTime}
             onChange={(e) => setUseStreamTime(e.target.checked)}
      />
      <br />
      <hr/>

      <ul style={{margin: 'auto', display:'inline-block', border:'1px black',textAlign:'left', listStyleType: 'none'}}>
        {chapters.map((chapter, i) => (<li  key={i}>{chapter}</li>))}
      </ul>

    </div>
  )
}

export default MatchEventsTable